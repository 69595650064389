import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { EclipseStore, EclipseState } from './eclipse.store';

@Injectable({ providedIn: 'root' })
export class EclipseQuery extends Query<EclipseState> {

  constructor(protected store: EclipseStore) {
    super(store);
  }

}
