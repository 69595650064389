import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    //canLoad: [IntroGuard, AutoLoginGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule),
    canLoad: [AuthGuard]
  },

  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'stock',
    loadChildren: () => import('./pages/stock/stock.module').then( m => m.StockPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'stockin-modal',
    loadChildren: () => import('./pages/stockin-modal/stockin-modal.module').then( m => m.StockinModalPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'customer-registration',
    loadChildren: () => import('./pages/customer-registration/customer-registration.module').then( m => m.CustomerRegistrationPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'organisation',
    loadChildren: () => import('./pages/organisation/organisation.module').then( m => m.OrganisationPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'recon',
    loadChildren: () => import('./pages/recon/recon.module').then( m => m.ReconPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'client-wallets',
    loadChildren: () => import('./pages/client-wallets/client-wallets.module').then( m => m.ClientWalletsPageModule)
  },
  {
    path: 'tankreadings',
    loadChildren: () => import('./pages/tankreadings/tankreadings.module').then( m => m.TankreadingsPageModule)
  },
  {
    path: 'fuel',
    loadChildren: () => import('./pages/fuel/fuel.module').then( m => m.FuelPageModule)
  }






];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
