import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { EclipseStore } from './eclipse.store';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/services/shared.service';
import { EclipseQuery, EclipseState } from '.';
import { v4 as uuidv4 } from 'uuid';
import { SessionService } from '../session';


const eclipseBaseUrl = environment.eclipseBaseURL;
const identity = environment.eclipseIdentity;
const password = environment.eclipsePassword;
const tenantId = environment.tenantId;
const walletTypeId = environment.zfWalletType;

@Injectable({ providedIn: 'root' })
export class EclipseService {
  [x: string]: any;

  constructor(
    private eclipseStore: EclipseStore,
    private http: HttpClient,
    private sharedService: SharedService,
    private eclipseQuery: EclipseQuery,
    private sessionService: SessionService
    ) {
  }

  async get() {
    /* this.sharedService.presentLoading('Logging into eclipse');
     await this.http.post(
       `${eclipseBaseUrl}​authentication/login`, {
        "identity": identity,
        "password": password
      },
       ).toPromise().then((data: any) => {
        this.eclipseStore.update({data});
       });
     this.sharedService.dismissLoading();*/

    if(this.eclipseQuery.getValue().data.headerValue === null){
      console.log('inside the get function');
      await this.sessionService.request('GET', 'GetUkheskeAuthTokenService?format=json').then(result => {
        if (result[0].status.code === 1) {
          console.log(result[0].status.errorText);
          this.sharedService.presentError('Error', result[0].status.errorText);
          this.eclipseStore.reset();
          return;
        }
        const authorization = result[0].objectList[0].Response_data.slice(15,-31);
        const secs =result[0].objectList[0].Response_data.slice(-12,-2);
        const now=Math.floor(Date.now()/1000);
        console.log(secs-now);
        const renewSecs=secs-now;
        const data: EclipseState = {
          data : {
            headerName: null,
            headerValue: authorization,
            sessionId: null,
            tenantId: null,
            expires: null,
            roles: [],
            expiresEpochSecs: null,
            customerId: null,
            renewSecs:renewSecs
          }
        };
        this.eclipseStore.update(data);

      }).catch(error => {
        console.log(error);
        this.sharedService.presentError('Error', error.error);
        this.eclipseStore.reset();
        return error;
      });
     }else {
       return;
     }
  }

  async renewToken(){

    console.log('ukeshe renew call');
    await this.sessionService.request('GET', 'GetUkheskeAuthTokenService?format=json').then(result => {
      if (result[0].status.code === 1) {
        console.log(result[0].status.errorText);
        this.sharedService.presentError('Error', result[0].status.errorText);
        this.eclipseStore.reset();
        return;
      }
      const authorization = result[0].objectList[0].Response_data.slice(15,-31);
      const secs =result[0].objectList[0].Response_data.slice(-12,-2);
      const now=Math.floor(Date.now()/1000);
      console.log(secs-now);
      const renewSecs=secs-now;
      const data: EclipseState = {
        data : {
          headerName: null,
          headerValue: authorization,
          sessionId: null,
          tenantId: null,
          expires: null,
          roles: [],
          expiresEpochSecs: null,
          customerId: null,
          renewSecs:renewSecs
        }
      };
      this.eclipseStore.update(data);
      console.log("Renew complete");
    }).catch(error => {
      console.log(error);
      this.sharedService.presentError('Error', error.error);
      this.eclipseStore.reset();
      return error;
    });
  }


  async registration(details: any){

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.eclipseQuery.getValue().data.headerValue
      })
    };

    console.log(details);

    const result = this.http.post(
      `${eclipseBaseUrl}/tenants/${tenantId}/customers`,
      details,
      httpOptions,
    );

    console.log(result);
    return result;

  }



  async docUpload(docType: string, customerId: string, base64Doc: string){
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.eclipseQuery.getValue().data.headerValue
      })
    };

    return this.http.post(
      `${eclipseBaseUrl}/tenants/${tenantId}/customers/${customerId}/documents`,
      {
        "base64EncodedDocument": base64Doc,
        "documentType": docType,
        "mediaType": "image/jpeg"
      },httpOptions,
    );
  }
  async ratify(customerId: string){
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.eclipseQuery.getValue().data.headerValue
      })
    };

    return this.http.post(
      `${eclipseBaseUrl}/tenants/${tenantId}/customers/${customerId}/ratify`,
      {
      }, httpOptions,
    );
  }

  async createWallet(customerId: string){
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.eclipseQuery.getValue().data.headerValue
      })
    };

    return this.http.post(
      `${eclipseBaseUrl}/tenants/${tenantId}/customers/${customerId}/wallets`,
      {
        "cardType": "virtual",
        "customerSearch": "",
        "description": "zire fuel customer wallet",
        "externalUniqueId": uuidv4(),
        "name": "Fuel Wallet",
        "searchBy": "lastName",
        "status": "ACTIVE",
        "walletTypeId": walletTypeId,
      }, httpOptions,
    );
  }



}
