import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface SessionState {
  username: string | null;
  ha1: string | null;
  data: {
    recordNumber: number,
    recordName: string,
    title: string,
    description: string,
    logoUrl: string,
    theme: string,
    colorScheme: string,
    userName: string,
    firstName: string,
    lastName: string,
    userUniqueId: string, //profile ID
    userImageUrl: string,
    userImageWidth: string,
    userImageHeight: string,
    userLanguage: string,
    isGuest: string,
    canAddProfiles: string,
    canAddProjects: string,
    canInviteByEmail: string,
    canPostActivity: string,
    canPostEvents: string,
    canPostPhotos: string,
    canPostTopics: string,
    canCreateNotifications: string,
    lastCheckInDate: string,
    lastLocation: string,
  }
}

export function createInitialState(): SessionState {
  return {
    username: null,
    ha1: null,
    data: {
      recordNumber: null,
      recordName: null,
      title: null,
      description: null,
      logoUrl: null,
      theme: null,
      colorScheme: null,
      userName: null,
      firstName: null,
      lastName: null,
      userUniqueId: null,
      userImageUrl: null,
      userImageWidth: null,
      userImageHeight: null,
      userLanguage: null,
      isGuest: null,
      canAddProfiles: null,
      canAddProjects: null,
      canInviteByEmail: null,
      canPostActivity: null,
      canPostEvents: null,
      canPostPhotos: null,
      canPostTopics: null,
      canCreateNotifications: null,
      lastCheckInDate: null,
      lastLocation: null,
      // customerId: null,
      // walletId: null,
    }
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'session' })
export class SessionStore extends Store<SessionState> {

  constructor() {
    super(createInitialState());
  }

}
