import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { resetStores } from '@datorama/akita';
import { Md5 } from 'ts-md5';
import { tap } from 'rxjs/operators';
import { Http, HttpResponse } from '@capacitor-community/http';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/services/shared.service';
import { NavController } from '@ionic/angular';
import { present } from '@ionic/core/dist/types/utils/overlays';
import { SessionStore } from './session.store';
import { SessionQuery } from '.';
import { EclipseQuery, EclipseService } from '../eclipse';

const base = environment.connectBaseUrl + 'api/';
const key = environment.key;
const realm = environment.realm;

@Injectable({ providedIn: 'root' })
export class SessionService {

  auth: any;
  os = "";
  device: any;
  eclipseData: any;

  constructor(
    private sessionStore: SessionStore,
    private http: HttpClient,
    private sessionQuery: SessionQuery,
    private sharedService: SharedService,
    public navCtrl: NavController,
    //private eclipseService: EclipseService,
    private eclipseQuery: EclipseQuery,

    ) {
      this.sessionQuery.select(["username", "ha1"]).subscribe((user) => {
        this.auth = user;
      });
      this.eclipseQuery.select().subscribe(eclipse => {
        this.eclipseData = eclipse;
      });
  }

  async request(method, uri, params?) {
    const ha2 = Md5.hashStr(`${method}:${uri}`);
    const nonce = new Date().getTime();
    const response = Md5.hashStr(`${this.auth.ha1}:${nonce}:${ha2}`);
    const Authorization = `Digest username="${this.auth.username}",realm="${realm}", nonce="${nonce}",uri="${uri}",response="${response}"`;
    const url = `${base}${uri}`;
    const headers = {
      //"origin": 'https://zirefuels-dashboard.connectmobiles24.com/',
      //"Origin": 'https://zirefuels-dashboard.connectmobiles24.com/',
      //"Access-Control-Allow-Origin" : 'https://zirefuels-dashboard.connectmobiles24.com/',
      "X-Concursive-Key": `key=${key}`,
      // "X-Concursive-Platform": this.device.operatingSystem,
      // "X-Concursive-Model": this.device.name,
      "Content-Type": "application/json",
      'Access-Control-Request-Method': 'GET',
      'Access-Control-Request-Headers': 'Content-Type,Accept,X-Concursive-Key',
      'Origin': 'https://zirefuels-dashboard.connectmobiles24.com',
      Authorization


    };

    let request: HttpResponse;

    if (!params) {
      params = {};
    }
    params.format = 'json';

    if (method === 'GET') {
      request = await Http.request({
        method,
        url,
        headers,
        params
      });
    }else if (uri === 'registration') {
      request = await Http.request({
        method,
        url,
        headers,
        params
      });
    } else {
      request = await Http.request({
        method,
        url,
        headers,
        data: params
      });
    }

    return request.data;
  }

  async checkAccess(data:string){
    return await this.request('GET', 'profileList',{
        profile:'zirefuels-admin-dashbord'
    }).then(result => {
      if (result[0].status.code !== 0){
        this.sharedService.presentError('Access Denied', 'You do not have access to Zire Fuels Admin Dashboard');
        return;
      }else{

        this.navCtrl.navigateRoot('admin');
        this.sharedService.presentToast(`${data} has successfully logged in`, 'success');

      }
    });
}
  
  async login(username, password) {
    this.sharedService.presentLoading('Signing in');
    const ha1 = Md5.hashStr(`${username}:${environment.realm}:${password}`).toString();
    this.sessionStore.update({ username, ha1 });
    return await this.request('GET', 'site').then(async result => {
      if (result[0].status.code !== 0){
        this.sharedService.presentError('Error', result[0].status.errorText);
        return;
      }
      const data = result[0].objectList[0];
      this.sessionStore.update({ data });
      //const something = this.userQuery.getValue();
      //console.log(something)
      if (data?.isGuest === 'false') {
        await this.checkAccess(data.firstName);
        //this.eclipseService.get();

      
        //TODO: clean up this code
      } else {
        //this.sharedService.presentError('Error', 'incorrect username/password');
        //this.userStore.reset();
        return;
      }
      return result[0];
    }).catch(error => {
      console.log(error);
      this.sharedService.presentError('Error', 'incorrect username / password');
      this.sharedService.presentToast('Unable to login', 'danger');
      this.sessionStore.reset();
      return error;
    }).finally(() => {
      this.sharedService.dismissLoading();
    });
  }

  logout() {
    console.log('logging out');
    resetStores();
    this.navCtrl.navigateRoot('login');
  }

  get() {
    return this.http.get('').pipe(tap(entities => this.sessionStore.update(entities)));
  }

  // getwalletID(){
  //   await this.userService.request('GET',
  //     //fetching wallet details using the buisiness unique id
  //     `GetUkhesheWalletsForProfile`, {org_id: this.business.uniqueId}).then(result => {
  //       //resetStores()
  //       if(result){
  //       this.ukesheStore.update({customerId: result[0].objectList[0].customer_id});
  //       this.ukesheStore.update({walletId: result[0].objectList[0].wallet_id});
  //       }
  //       //console.log(result[0].objectList[0].wallet_id);
  //     }).catch(error => {
  //       console.log(error);
  //       //this.sharedService.presentError('Error', 'error.error');
  //       //this.ukesheStore.reset();
  //       return error;
  //     });
  // }

}
