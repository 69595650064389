import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { SessionQuery } from '../state/session';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {

  constructor(
    private sessionQuery: SessionQuery,
    private router: Router,
  ){ }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.sessionQuery.isLoggedIn$.pipe(
        filter(val => val != null),
        take(1),
        map(isLoggedIn => {
          console.log('Guard: ', isLoggedIn);

          if(isLoggedIn) {
            return true;
          } else {
            this.router.navigateByUrl('/login');
            return false;
          }
        })
      );
  }
}
