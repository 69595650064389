import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface EclipseState {
  data: {
    headerName: string | null;
    headerValue: string | null;
    sessionId: string | null;
    tenantId: number | null;
    expires: string | null;
    roles: [];
    expiresEpochSecs: number | null;
    customerId: any|null;
    renewSecs:any|null
  };

}

export function createInitialState(): EclipseState {
  return {
    data: {
      headerName: null,
      headerValue: null,
      sessionId: null,
      tenantId: null,
      expires: null,
      roles: [],
      expiresEpochSecs: null,
      customerId: null,
      renewSecs:null
    }

  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'eclipse',resettable:true })
export class EclipseStore extends Store<EclipseState> {

  constructor() {
    super(createInitialState());
  }

}
