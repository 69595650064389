/*
This service is used by all components & pages to provide various
functionality to avoid repeating code
*/

import { Injectable } from '@angular/core';
import { Device, DeviceInfo } from '@capacitor/device';
import {
  ModalController,
  IonRouterOutlet,
  LoadingController,
  AlertController,
  ToastController
} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  currentModal: any;
  isLoading = false;
  info: DeviceInfo;
  modalInst = [];
  modali = 0;

  //token dates
  t1:any;
  t2:any;

  //tank1 dates
  tank1Date:any;
  tank1start:any;
  tank1end:any;

  //tank2 dates
  tank2Date:any;
  tank2start:any;
  tank2end:any;

  constructor(
    public toastCtrl: ToastController,
    public modalCtrl: ModalController,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController
  ) {}

  getInfo() {
    return this.info;
  }

public gett1(){
return this.t1;
}
public gett2(){
  return this.t2;
}

getTank1date(){
  return this.tank1Date;
}

getTank2date(){
  return this.tank2Date;
}

getTank1start(){
  return this.tank1start;
}

getTank2start(){
  return this.tank2start;
}

getTank1end(){
  return this.tank1end;
}
getTank2end(){
  return this.tank2end;
}


public setT1(t1: any): void {
  this.t1 = t1;
}
public setT2(t2: any): void {
  this.t2 = t2;
}

public setTank1date(t1: any): void {
  this.tank1Date = t1;
}

public setTank2date(t1: any): void {
  this.tank2Date = t1;
}

public setTank1start(t1: any): void {
  this.tank1start = t1;
}
public setTank2start(t1: any): void {
  this.tank2start = t1;
}
public setTank1end(t1: any): void {
  this.tank1end = t1;
}
public setTank2end(t1: any): void {
  this.tank2end = t1;
}




  async getDeviceInfo() {
    await Device.getInfo().then(result => {
      this.info = result;
    });
  }

  async presentModal(routerOutlet: IonRouterOutlet, component) {
    const modal = await this.modalCtrl.create({
      component,
      swipeToClose: true,
      presentingElement: routerOutlet.parentOutlet.nativeEl
    });
    this.currentModal = modal;
    return await modal.present();
  }

  async presentLoading(message?: string) {
    this.isLoading = true;
    return await this.loadingCtrl
      .create({
        message
      })
      .then(loading => {
        loading.present().then(() => {
          if (!this.isLoading) {
            loading.dismiss();
          }
        });
      });
  }

  async presentError(header: string, message: string) {
    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons: [
        {
          text: 'Dismiss'
        }
      ]
    });

    return await alert.present();
  }

  async presentToast(message: string, color?: string) {
    const toast = await this.toastCtrl.create({
      message,
      color,
      duration: 2000
    });
    toast.present();
  }

  async dismissLoading() {
    this.isLoading = false;
    return await this.loadingCtrl.dismiss();
  }

  storeModal(x) {
    this.modalInst[this.modali] = x;
    this.modali++;
  }
  closeModals() {
    for (var i = 0; i < this.modalInst.length; i++) {
      this.modalInst[i].dismiss();
    }
  }
}

