// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //eclipseBaseURL: 'https://eclipse-java-sandbox.jini.rocks/eclipse-conductor/rest/v1/',
  eclipseBaseURL: 'https://ukheshe.live/eclipse-conductor/rest/v1',
  // connectBaseUrl: 'https://ziretest.connectmobiles24.com/',
  // key: 'ca2fb7ac-c659-4ee6-96ba-f2ad111a3c7b',
//  connectBaseUrl: 'https://ziredev.connectmobiles24.com/',
//   key: '34a06a25-aea3-40de-b176-250f64cf0d5a',
  connectBaseUrl: 'https://zire.co.za/',
     key: '00353d91-70da-4cb0-b63a-276264fc40be',
    //connectBaseUrl: 'https://staging.zire.co.za/',
   // key: 'd511ad58-b63a-4e70-868e-4d8cb9822bca',
  realm: 'ConcourseConnect',
  tank1Id: 1382,
  tank2ID: 1383,
  eclipseIdentity: 'sizwe123',
  eclipsePassword: 'sizwe123',
  // zfWallet: 2196,
  //tenantId: 436,
  tenantId: 335,
  zfWalletType:  248//35,

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
